import Vue from 'vue'

export default {
  SET_CASHIER (state, payload) {
    state.cashier = Object.assign({}, state.cashier, payload) //precisa ser assim para manter a reatividade
  },
  // UPDATE_CASHIER (state, payload) {
  //   state.cashier = Object.assign({}, state.cashier, payload) //precisa ser assim para manter a reatividade
  // },

  //MANIPULA O OBJETO DO ESTADO
  SET (state, payload) {
    state.myCashierTransaction = Object.assign({}, state.myCashierTransaction, payload) //precisa ser assim para manter a reatividade
  },

  //ADICIONA UM NOVO ÍTEM NO ARRAY
  ADD (state, payload) {
    state.myCashierTransactions.push(payload) //precisa ser assim para manter a reatividade
  },

  UPDATE (state, payload) {
    const index = state.myCashierTransactions.data.findIndex((o) => o.id === payload.id)
    Vue.set(state.myCashierTransactions.data, index, payload)
  },

  //SUBSTITUI O ARRAY POR UM NOVO
  REFRESH_CASHIERS (state, payload) {
    state.cashiers = payload
  },
  
  //ÚLTIMAS POSIÇÕES DOS CAIXAS DE CADA USUÁRIO (USADO EM CONTAS PARA MOSTRAR A POSIÇÃO ATUAL DOS CAIXAS)
  REFRESH_USERS_LAST_CASHIERS (state, payload) {
    state.usersLastCashiers = payload
  },

  //SUBSTITUI O ARRAY POR UM NOVO
  REFRESH (state, payload) {
    state.myCashierTransactions = payload
  },

  //EXCLUI DO ARRAY
  DELETE (state, payload) {
    const index = state.myCashierTransactions.data.findIndex(o => o.id === payload)
    state.myCashierTransactions.data.splice(index, 1) //precisa ser assim para manter a reatividade
    state.myCashierTransactions.total = state.myCashierTransactions.total - 1 //precisa ser assim para manter a reatividade
    if (state.myCashierTransaction && state.myCashierTransaction.id === payload) {
      state.myCashierTransaction = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }
  }
}
