import state from './moduleMyCashierState.js'
import mutations from './moduleMyCashierMutations.js'
import actions from './moduleMyCashierActions.js'
import getters from './moduleMyCashierGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

