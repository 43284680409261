<template>
  <div>
    <vs-popup class="popup90" :fullscreen="false" title="Boleto(s)" :active.sync="popupShowReceipt">
      <div id="boleto-loading" class="vs-con-loading__container">
        <embed :src="receiptUrl" alt="Recibo" width="100%" height="600" type="application/pdf" pluginspage="http://www.adobe.com/products/acrobat/readstep2.html">
      </div>
    </vs-popup>

    <vs-popup class="popup90" :fullscreen="false" title="Quitar parcelas em lote" :active.sync="localShow">

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="batchPaymentApply(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <div class="vx-row">
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full mt-0  mb-2 flex items-center">
          <vs-checkbox v-model="selectAllCheckBox" :disabled="!transactions.length" icon-pack="feather" :icon="selectAllIcon" class="select-all-chexkbox">Selecionar Tudo</vs-checkbox>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full mt-0  mb-2 flex items-center">
          <vs-checkbox v-model="generateReceipt" icon-pack="feather" icon="icon-check" class="ml-4">Emitir Recibo</vs-checkbox>
        </div>
        <div class="vx-col md:w-1/3 sm:w-1/2 w-full mt-0  mb-2 flex items-center">
          <vx-tooltip text="Seleção do Período" position="top" class="h-5 w-5 ml-6 mb-6">
            <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
              <a href.prevent class="flex cursor-pointer">
                <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 inline-flex rounded-full" :class="[`text-primary`, 'mb-0']"
                  :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
              </a>
              <vs-dropdown-menu ref="dropMenu" class="w-60" style="z-index: 999999999999;">
                    <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.firstPeriod" class="w-full" label="Data Inicial" />
                    <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.lastPeriod" class="w-full" label="Data Final" />
                    <span class="text-danger text-sm" v-show="periodError">O período inicial deve ser anterior ao final.</span>
              </vs-dropdown-menu>
            </vs-dropdown>
          </vx-tooltip>
          <h5 class="inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4">{{ period }}</h5>
          <v-select class="m-l-4 vue_select_drop_size_100 w-60" :clearable="false" @input="filterBatchPayment" v-model="filter"
            :reduce="option => option.value" :options="[
              {label: 'Ver Tudo', value: 'ALL'},
              {label: 'Entradas', value: 'CREDIT'},
              {label: 'Saídas', value: 'DEBIT'}
            ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>

        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full mt-0  mb-2 flex items-center">
          <vs-button class="ml-auto" color="secondary" @click="cancel">Cancelar</vs-button>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full mt-0  mb-2 flex items-center">
          <vs-button class="ml-4" @click="batchPaymentApply()" :disabled="!selectedTransactions.length || !validateBatchPayment || disableSave">Quitar</vs-button>
        </div>
      </div>

      <vs-table v-if="transactions.length" :data="transactions.filter(tr => { if (filter !== 'ALL') { return tr.type === filter } else { return true }})" hoverFlat stripe style="overflow: -webkit-paged-y">
        <template slot="thead">
          <vs-th>#</vs-th>
          <vs-th>Vencimento</vs-th>
          <vs-th>Descrição</vs-th>
          <vs-th>Forma Pagamento</vs-th>
          <vs-th>Conta</vs-th>
          <vs-th>Valor Parcela</vs-th>
          <vs-th>Valor Pago</vs-th>
          <vs-th>Data Pagamento</vs-th>
          <!-- <vs-th>Plano de Contas</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="i" v-for="(transaction, i) in data" style="opacity: 1 !important">
            <vs-td><vs-checkbox v-model="selectedTransactions" :vs-value="transaction" @change="changeCheckBox(transaction)" class="vs-checkbox-small ml-0 mr-1"></vs-checkbox></vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" v-model="transaction.expiration_date"
                data-vv-as="Vencimento" data-vv-scope="batchPayment" v-validate.initial="{ required: selectedTransactions.some(e => e.id === transaction.id) }" :name="'expiration_date'+i" />
              <span class="text-danger text-sm" v-show="errors.has('batchPayment.expiration_date' + i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <span :style="transaction.type === 'DEBIT' && 'color: red'">{{ transaction.student ? transaction.student.name : '' }} {{ transaction.observation }} {{ transaction.servicePackages && transaction.servicePackages.map(a => ` ${a['name']} ${a['category_cnh'] || ''}`).join(", ") }}</span>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="transaction.type_payment_id"
                :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                data-vv-as="Tipo de Pagamento" data-vv-scope="batchPayment" v-validate.initial="{ required: selectedTransactions.some(e => e.id === transaction.id) && !transaction.type_payment_id }" :name="'type_payment_id'+i">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('batchPayment.type_payment_id'+i)">Campo obrigatório</span>
            </vs-td>
            <vs-td>
              <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!transaction.type_payment_id" v-model="transaction.account_id"
                :reduce="option => option.value" :options="transaction.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                data-vv-as="Conta" data-vv-scope="batchPayment" v-validate.initial="{ required: selectedTransactions.some(e => e.id === transaction.id) && !transaction.account_id }" :name="'account_id'+i">
                <template #no-options="{}">
                  Nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('batchPayment.account_id'+i)">Campo obrigatório</span>
            </vs-td>

            <vs-td>
                <currency-input class="w-32 vs-inputx vs-input--input normal hasValue"
                  v-model="transaction.value_parcel"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
                <currency-input class="w-32 vs-inputx vs-input--input normal hasValue"
                  :disabled="!selectedTransactions.some(e => e.id === transaction.id)"
                  v-model="transaction.value_pay"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </vs-td>
            <vs-td>
              <vs-input type="date" class="w-48" v-model="transaction.payday" @change="checkPayday(i)"
                data-vv-as="Vencimento" data-vv-scope="batchPayment" :disabled="!selectedTransactions.some(e => e.id === transaction.id)"
                v-validate.initial="{ required: selectedTransactions.some(e => e.id === transaction.id) }" :name="'payday'+i" />
              <span class="text-danger text-sm" v-show="errors.has('batchPayment.payday'+i)">Campo obrigatório</span>
              <span class="text-danger text-sm" v-show="transaction.futureDate">Data futura não permitida</span>
            </vs-td>
          </vs-tr>

          <vs-tr style="opacity: 1 !important">
            <vs-td colspan="8">
              <vs-divider></vs-divider>
              <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center">
                  <vs-button class="ml-auto mb-2" color="secondary" @click="cancel">Cancelar</vs-button>
                  <vs-button class="ml-4 mb-2" @click="batchPaymentApply()" :disabled="!selectedTransactions.length || !validateBatchPayment || disableSave">Quitar Parcelas</vs-button>
                </div>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-alert v-if="!transactions.length" class="mt-4 mb-4" :active="true" color="warning" icon-pack="feather" icon="icon-info" style="height: auto">
        <span>Nenhuma parcela em aberto para o período selecionado!</span>
      </vs-alert>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

// Store Module
import moduleMyCashier from '@/store/my-cashier/moduleMyCashier.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'

import { CurrencyInput } from 'vue-currency-input'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

export default {
  components: {
    CurrencyInput,
    vSelect,
    permissionPassword
  },
  props: {
    show: {
      type: Boolean, default: false
    }
  },

  computed: {
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    validateBatchPayment () {
      return !this.errors.any('batchPayment')
    },
    selectAllCheckBox: {
      get () {
        return this.selectedTransactions.length
      },
      set (value) {
        const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
        if (value) {
          this.selectedTransactions = this.transactions
          this.selectedTransactions.map(e => {
            if (!e.payday) e.payday = `${YYYY}-${MM}-${DD}`
            e.value_pay = e.value_parcel
          })
        } else {
          this.selectedTransactions = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedTransactions.length === this.transactions.length ? 'icon-check' : 'icon-minus'
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    period () {
      if (this.payload.searchQuery.firstPeriod && this.payload.searchQuery.lastPeriod) {
        return `${moment(this.payload.searchQuery.firstPeriod).format('DD/MM/YYYY')} à ${moment(this.payload.searchQuery.lastPeriod).format('DD/MM/YYYY')}`
      }
      return `${moment().format('DD/MM/YYYY')} à ${moment().format('DD/MM/YYYY')}`
    }
  },
  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.$emit('cancel')
        this.resetBatchModal() //reseta o form ao fechar a modal
      } else {
        this.$vs.loading()
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.filterDate()
      }
    }
  },
  data () {
    return {
      localShow: false,
      popupPermissionPassword: false,
      disableSave: false,
      payload: {
        offset: 0,
        limit: 40,
        searchQuery: {
          firstPeriod: '',
          lastPeriod: '',
          responsible: ''
        },
        params: null,
        consultApi: true
      },
      periodError: false,

      popupShowReceipt: false,
      receiptUrl: '',
      generateReceipt: false,
      selectedTransactions: [],
      transactions: [],
      filter: 'ALL'
    }
  },
  methods: {
    filterBatchPayment () {
      this.selectedTransactions = []
    },
    //EVITA BAIXA COM DATA FUTURA
    checkPayday (index) {
      const transaction = this.transactions[index]
      if (moment(transaction.payday).year() > 1000) {
        const difDays = moment(transaction.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          transaction.futureDate = true
          this.$set(this.transactions, index, transaction)
          this.disableSave = true
        } else {
          transaction.futureDate = false
          this.$set(this.transactions, index, transaction)
          //checa se há alguma parcela com pagamento futuro
          const i = this.transactions.findIndex(e => e.futureDate)
          if (i === -1) this.disableSave = false
        }
      } else {
        transaction.futureDate = false
        this.$set(this.transactions, index, transaction)
        //checa se há alguma parcela com pagamento futuro
        const i = this.transactions.findIndex(e => e.futureDate)
        if (i === -1) this.disableSave = false
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    resetBatchModal () {
      this.selectAllCheckBox = false
      this.generateReceipt = false
    },
    changeCheckBox (transaction) {
      const index = this.selectedTransactions.findIndex(o => o.id === transaction.id)
      if  (index !== -1) {
        if (this.selectedTransactions[index] && !this.selectedTransactions[index].payday) {
          const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
          this.selectedTransactions[index].payday = `${YYYY}-${MM}-${DD}`
        }
        this.selectedTransactions[index].value_pay = this.selectedTransactions[index].value_parcel
      } else {
        transaction.payday = ''
        transaction.value_pay = 0
        transaction.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const i = this.transactions.findIndex(e => e.futureDate)
        if (i === -1) this.disableSave = false
      }
    },
    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const transaction = this.transactions[i]
      transaction.account_id = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === transaction.type_payment_id)
      })
      transaction.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) transaction.account_id = myArrayFiltered[0].value
    },
    batchPaymentApply (pass = false) {
      /**
       * CHECAR PAGAMENTO RETROATIVO
       */
      if (!pass) {
        const retroactiveParcels = this.selectedTransactions.filter(e => {
          if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
        })

        if (retroactiveParcels.length) {
          this.popupPermissionPassword = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Há parcelas com baixa retroativa',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
      }

      this.$vs.loading()
      this.$store.dispatch('myCashier/batchPayment', { batchPayment: this.selectedTransactions, generateReceipt: this.generateReceipt })
        .then((response) => {
          //AJUSTE DE SALDO DE CAIXA
          this.selectedTransactions.forEach(transaction => {
            if (transaction.type === 'CREDIT') {
              this.$store.commit('myCashier/SET_CASHIER', {
                final_balance: this.$store.state.myCashier.cashier.final_balance + transaction.value_pay
              })
              if (moment(transaction.payday).diff(moment(this.today), 'days') < 0) {
                //retroativo, devo incrementar saldo inicial também
                this.$store.commit('myCashier/SET_CASHIER', {
                  opening_balance: this.$store.state.myCashier.cashier.opening_balance + transaction.value_pay
                })
              }
            } else {
              this.$store.commit('myCashier/SET_CASHIER', {
                final_balance: this.$store.state.myCashier.cashier.final_balance - transaction.value_pay
              })
              if (moment(transaction.payday).diff(moment(this.today), 'days') < 0) {
                //retroativo, devo incrementar saldo inicial também
                this.$store.commit('myCashier/SET_CASHIER', {
                  opening_balance: this.$store.state.myCashier.cashier.opening_balance - transaction.value_pay
                })
              }
            }
          })

          this.$vs.loading.close()
          this.localShow = false
          this.$emit('saved')
          if (response.data.pdf) {
            this.receiptUrl = `${process.env.VUE_APP_URL}/storage/${response.data.pdf}`
            this.popupShowReceipt = true
          }
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Parcelas Baixadas.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })


      //           try {
      //               this.overlayBatchPayment = true
      //               const response = await axios.post('/myCashier/batchPayments',{
      //                   student : this.studentProp,
      //                   batchPayment : this.batchPayment
      //               });
      //               if(response.data.pdf){
      //                   window.open('/storage/'+response.data.pdf, '_blank');
      //                   let link = document.createElement('a');
      //                   link.href = '/storage/'+response.data.pdf;
      //                   link.download = response.data.pdf+'.pdf';
      //                   link.dispatchEvent(new MouseEvent('click'));
      //               }
      //               this.atualizaSaldo()
      //               this.batchPaymentModalVisible = true
      //               this.$toast.success(response.data.message, response.data.title, this.optionsToast);
      //           } catch (error) {
      //               console.log(error);
      //               if(error.response.status === 401 || error.response.status === 419){
      //                   this.$toast.warning('Sua sessão expirou. Por favor atualize a página.', 'AVISO', this.optionsToast);
      //               } else {
      //                   this.$toast.error(error.response.data.message, 'Erro', this.optionsToast);
      //               }
      //           }
      //           this.$refs.dataTables.reload();
      //           this.overlayBatchPayment = false
      //           this.batchPaymentModalVisible = false


    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    filterDate () {
      if (moment(this.payload.searchQuery.firstPeriod).year() > 1000 && moment(this.payload.searchQuery.lastPeriod).year() > 1000) {
        const difDays = moment(this.payload.searchQuery.lastPeriod).diff(moment(this.payload.searchQuery.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial deve ser anterior ao final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        this.dispatchFilterDate()
      } else if (!this.payload.searchQuery.firstPeriod && !this.payload.searchQuery.lastPeriod) {
        this.periodError = false
        this.dispatchFilterDate()
      }
    },
    dispatchFilterDate () {
      this.$store.dispatch('myCashier/fetchBatchPayment', { firstPeriod: this.payload.searchQuery.firstPeriod, lastPeriod: this.payload.searchQuery.lastPeriod })
        .then((resp) => {
          this.$vs.loading.close()
          this.transactions = JSON.parse(JSON.stringify(resp.data))
          //PRECISO FILTRAR E PREENCHER AS OPÇÕES DE CONTAS CONFORME O TIPO DE PAGAMENTO DE CADA TRANSAÇÃO
          this.transactions.map(transaction => {
            transaction.futureDate = false
            const myArrayFiltered = this.accounts.filter((account) => {
              return account.type_payments.some(e => e.id === transaction.type_payment_id)
            })
            transaction.accountsFilteredOptions = myArrayFiltered
          })
          this.$refs.dropMenu.dropdownVisible = false

        }).catch(err => { console.error(err) })
    }
  },
  created () {
    this.debouncedFilterDate = _.debounce(this.filterDate, 600)

    if (!moduleMyCashier.isRegistered) {
      this.$store.registerModule('myCashier', moduleMyCashier)
      moduleMyCashier.isRegistered = true
    }
    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
  }
}
</script>

<style>
.popup90 .vs-popup {
  width: 90% !important;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td  {
  padding: 1px 14px;
}
.vs-tooltip {
  z-index: 99999;
}
</style>
