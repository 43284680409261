import axios from '@/axios.js'

export default {
  setCashier ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/cashier/getMyLast`)
        .then((response) => {
          commit('SET_CASHIER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  closeCashier ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/cashier/close`, state.cashier)
        .then((response) => {
          commit('SET_CASHIER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  openCashier ({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/cashier/open`, state.cashier)
        .then((response) => {
          commit('SET_CASHIER', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  //NO FILTER SEMPRE DEVO FAZER A CONSULTA A API PARA RENOVAR O ESTADO POR COMPLETO no GRID
  fetchFilter ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/cashier/get`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          commit('SET_CASHIER', response.data.cashier)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // LISTA CAIXAS POR DATA (NO MOMENTO LISTANDO SOMENTE DE UMA DATA SEM PERÍODO)
  fetchCashiers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/cashier/fetchCashiers`, payload)
        .then((response) => {
          commit('REFRESH_CASHIERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // LISTA AS ÚLTIMAS POSIÇÕES DOS CAIXAS DE CADA USUÁRIO (USADO EM CONTAS PARA MOSTRAR A POSIÇÃO ATUAL DOS CAIXAS)
  fetchUsersLastCashiers ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/cashier/fetchUsersLastCashiers`, payload)
        .then((response) => {
          commit('REFRESH_USERS_LAST_CASHIERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchBatchPayment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transactions/getBatchPayment`, payload)
        .then((response) => {
          commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  batchPayment (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction/batchPayments`, payload)
        .then((response) => {
          // commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  store (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/transaction`, payload)
        .then((response) => {
          // commit('REFRESH', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  chargeback ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/transaction/chargeback/${payload.id}`, payload)
        .then((response) => {
          commit('UPDATE', response.data)
          commit('SET', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
