var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Boleto(s)",
            active: _vm.popupShowReceipt,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowReceipt = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vs-con-loading__container",
              attrs: { id: "boleto-loading" },
            },
            [
              _c("embed", {
                attrs: {
                  src: _vm.receiptUrl,
                  alt: "Recibo",
                  width: "100%",
                  height: "600",
                  type: "application/pdf",
                  pluginspage:
                    "http://www.adobe.com/products/acrobat/readstep2.html",
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: {
            fullscreen: false,
            title: "Quitar parcelas em lote",
            active: _vm.localShow,
          },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.batchPaymentApply(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              {
                staticClass:
                  "vx-col md:w-1/6 sm:w-1/2 w-full mt-0 mb-2 flex items-center",
              },
              [
                _c(
                  "vs-checkbox",
                  {
                    staticClass: "select-all-chexkbox",
                    attrs: {
                      disabled: !_vm.transactions.length,
                      "icon-pack": "feather",
                      icon: _vm.selectAllIcon,
                    },
                    model: {
                      value: _vm.selectAllCheckBox,
                      callback: function ($$v) {
                        _vm.selectAllCheckBox = $$v
                      },
                      expression: "selectAllCheckBox",
                    },
                  },
                  [_vm._v("Selecionar Tudo")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "vx-col md:w-1/6 sm:w-1/2 w-full mt-0 mb-2 flex items-center",
              },
              [
                _c(
                  "vs-checkbox",
                  {
                    staticClass: "ml-4",
                    attrs: { "icon-pack": "feather", icon: "icon-check" },
                    model: {
                      value: _vm.generateReceipt,
                      callback: function ($$v) {
                        _vm.generateReceipt = $$v
                      },
                      expression: "generateReceipt",
                    },
                  },
                  [_vm._v("Emitir Recibo")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "vx-col md:w-1/3 sm:w-1/2 w-full mt-0 mb-2 flex items-center",
              },
              [
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "h-5 w-5 ml-6 mb-6",
                    attrs: { text: "Seleção do Período", position: "top" },
                  },
                  [
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer hover:text-primary",
                        attrs: {
                          "vs-custom-content": "",
                          "vs-trigger-click": "",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "flex cursor-pointer",
                            attrs: { "href.prevent": "" },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "p-2 inline-flex rounded-full",
                              class: [`text-primary`, "mb-0"],
                              style: {
                                background: `rgba(var(--vs-success),.15)`,
                              },
                              attrs: {
                                icon: "CalendarIcon",
                                svgClasses: "h-6 w-6",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "vs-dropdown-menu",
                          {
                            ref: "dropMenu",
                            staticClass: "w-60",
                            staticStyle: { "z-index": "999999999999" },
                          },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { type: "date", label: "Data Inicial" },
                              on: { change: _vm.debouncedFilterDate },
                              model: {
                                value: _vm.payload.searchQuery.firstPeriod,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.payload.searchQuery,
                                    "firstPeriod",
                                    $$v
                                  )
                                },
                                expression: "payload.searchQuery.firstPeriod",
                              },
                            }),
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { type: "date", label: "Data Final" },
                              on: { change: _vm.debouncedFilterDate },
                              model: {
                                value: _vm.payload.searchQuery.lastPeriod,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.payload.searchQuery,
                                    "lastPeriod",
                                    $$v
                                  )
                                },
                                expression: "payload.searchQuery.lastPeriod",
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.periodError,
                                    expression: "periodError",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [
                                _vm._v(
                                  "O período inicial deve ser anterior ao final."
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "h5",
                  {
                    staticClass:
                      "inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4",
                  },
                  [_vm._v(_vm._s(_vm.period))]
                ),
                _c("v-select", {
                  staticClass: "m-l-4 vue_select_drop_size_100 w-60",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: [
                      { label: "Ver Tudo", value: "ALL" },
                      { label: "Entradas", value: "CREDIT" },
                      { label: "Saídas", value: "DEBIT" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.filterBatchPayment },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "vx-col md:w-1/6 sm:w-1/2 w-full mt-0 mb-2 flex items-center",
              },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-auto",
                    attrs: { color: "secondary" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("Cancelar")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "vx-col md:w-1/6 sm:w-1/2 w-full mt-0 mb-2 flex items-center",
              },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-4",
                    attrs: {
                      disabled:
                        !_vm.selectedTransactions.length ||
                        !_vm.validateBatchPayment ||
                        _vm.disableSave,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.batchPaymentApply()
                      },
                    },
                  },
                  [_vm._v("Quitar")]
                ),
              ],
              1
            ),
          ]),
          _vm.transactions.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    data: _vm.transactions.filter((tr) => {
                      if (_vm.filter !== "ALL") {
                        return tr.type === _vm.filter
                      } else {
                        return true
                      }
                    }),
                    hoverFlat: "",
                    stripe: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return [
                            _vm._l(data, function (transaction, i) {
                              return _c(
                                "vs-tr",
                                {
                                  key: i,
                                  staticStyle: { opacity: "1 !important" },
                                },
                                [
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-checkbox", {
                                        staticClass:
                                          "vs-checkbox-small ml-0 mr-1",
                                        attrs: { "vs-value": transaction },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeCheckBox(
                                              transaction
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.selectedTransactions,
                                          callback: function ($$v) {
                                            _vm.selectedTransactions = $$v
                                          },
                                          expression: "selectedTransactions",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required:
                                                _vm.selectedTransactions.some(
                                                  (e) => e.id === transaction.id
                                                ),
                                            },
                                            expression:
                                              "{ required: selectedTransactions.some(e => e.id === transaction.id) }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          "data-vv-as": "Vencimento",
                                          "data-vv-scope": "batchPayment",
                                          name: "expiration_date" + i,
                                        },
                                        model: {
                                          value: transaction.expiration_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              transaction,
                                              "expiration_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "transaction.expiration_date",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "batchPayment.expiration_date" +
                                                  i
                                              ),
                                              expression:
                                                "errors.has('batchPayment.expiration_date' + i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "span",
                                      {
                                        style:
                                          transaction.type === "DEBIT" &&
                                          "color: red",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            transaction.student
                                              ? transaction.student.name
                                              : ""
                                          ) +
                                            " " +
                                            _vm._s(transaction.observation) +
                                            " " +
                                            _vm._s(
                                              transaction.servicePackages &&
                                                transaction.servicePackages
                                                  .map(
                                                    (a) =>
                                                      ` ${a["name"]} ${
                                                        a["category_cnh"] || ""
                                                      }`
                                                  )
                                                  .join(", ")
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required:
                                                _vm.selectedTransactions.some(
                                                  (e) => e.id === transaction.id
                                                ) &&
                                                !transaction.type_payment_id,
                                            },
                                            expression:
                                              "{ required: selectedTransactions.some(e => e.id === transaction.id) && !transaction.type_payment_id }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.typePayments,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          "data-vv-as": "Tipo de Pagamento",
                                          "data-vv-scope": "batchPayment",
                                          name: "type_payment_id" + i,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.filterAccountsParcels(i)
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: transaction.type_payment_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              transaction,
                                              "type_payment_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "transaction.type_payment_id",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "batchPayment.type_payment_id" +
                                                  i
                                              ),
                                              expression:
                                                "errors.has('batchPayment.type_payment_id'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required:
                                                _vm.selectedTransactions.some(
                                                  (e) => e.id === transaction.id
                                                ) && !transaction.account_id,
                                            },
                                            expression:
                                              "{ required: selectedTransactions.some(e => e.id === transaction.id) && !transaction.account_id }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass:
                                          "vue_select_drop_size_100 w-48",
                                        attrs: {
                                          clearable: false,
                                          disabled:
                                            !transaction.type_payment_id,
                                          reduce: (option) => option.value,
                                          options:
                                            transaction.accountsFilteredOptions,
                                          placeholder: "Selecione",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          "data-vv-as": "Conta",
                                          "data-vv-scope": "batchPayment",
                                          name: "account_id" + i,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                Nenhum resultado encontrado.\n              "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: transaction.account_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              transaction,
                                              "account_id",
                                              $$v
                                            )
                                          },
                                          expression: "transaction.account_id",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "batchPayment.account_id" + i
                                              ),
                                              expression:
                                                "errors.has('batchPayment.account_id'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "w-32 vs-inputx vs-input--input normal hasValue",
                                        attrs: {
                                          currency: { prefix: "R$ " },
                                          valueAsInteger: false,
                                          distractionFree: false,
                                          precision: 2,
                                          autoDecimalMode: true,
                                          valueRange: { min: 0 },
                                          allowNegative: false,
                                        },
                                        model: {
                                          value: transaction.value_parcel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              transaction,
                                              "value_parcel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "transaction.value_parcel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("currency-input", {
                                        staticClass:
                                          "w-32 vs-inputx vs-input--input normal hasValue",
                                        attrs: {
                                          disabled:
                                            !_vm.selectedTransactions.some(
                                              (e) => e.id === transaction.id
                                            ),
                                          currency: { prefix: "R$ " },
                                          valueAsInteger: false,
                                          distractionFree: false,
                                          precision: 2,
                                          autoDecimalMode: true,
                                          valueRange: { min: 0 },
                                          allowNegative: false,
                                        },
                                        model: {
                                          value: transaction.value_pay,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              transaction,
                                              "value_pay",
                                              $$v
                                            )
                                          },
                                          expression: "transaction.value_pay",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: {
                                              required:
                                                _vm.selectedTransactions.some(
                                                  (e) => e.id === transaction.id
                                                ),
                                            },
                                            expression:
                                              "{ required: selectedTransactions.some(e => e.id === transaction.id) }",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        staticClass: "w-48",
                                        attrs: {
                                          type: "date",
                                          "data-vv-as": "Vencimento",
                                          "data-vv-scope": "batchPayment",
                                          disabled:
                                            !_vm.selectedTransactions.some(
                                              (e) => e.id === transaction.id
                                            ),
                                          name: "payday" + i,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.checkPayday(i)
                                          },
                                        },
                                        model: {
                                          value: transaction.payday,
                                          callback: function ($$v) {
                                            _vm.$set(transaction, "payday", $$v)
                                          },
                                          expression: "transaction.payday",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "batchPayment.payday" + i
                                              ),
                                              expression:
                                                "errors.has('batchPayment.payday'+i)",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Campo obrigatório")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: transaction.futureDate,
                                              expression:
                                                "transaction.futureDate",
                                            },
                                          ],
                                          staticClass: "text-danger text-sm",
                                        },
                                        [_vm._v("Data futura não permitida")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "vs-tr",
                              { staticStyle: { opacity: "1 !important" } },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { colspan: "8" } },
                                  [
                                    _c("vs-divider"),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-2 flex flex-wrap items-center",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "ml-auto mb-2",
                                                attrs: { color: "secondary" },
                                                on: { click: _vm.cancel },
                                              },
                                              [_vm._v("Cancelar")]
                                            ),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "ml-4 mb-2",
                                                attrs: {
                                                  disabled:
                                                    !_vm.selectedTransactions
                                                      .length ||
                                                    !_vm.validateBatchPayment ||
                                                    _vm.disableSave,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.batchPaymentApply()
                                                  },
                                                },
                                              },
                                              [_vm._v("Quitar Parcelas")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3957334256
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Descrição")]),
                      _c("vs-th", [_vm._v("Forma Pagamento")]),
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Valor Parcela")]),
                      _c("vs-th", [_vm._v("Valor Pago")]),
                      _c("vs-th", [_vm._v("Data Pagamento")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.transactions.length
            ? _c(
                "vs-alert",
                {
                  staticClass: "mt-4 mb-4",
                  staticStyle: { height: "auto" },
                  attrs: {
                    active: true,
                    color: "warning",
                    "icon-pack": "feather",
                    icon: "icon-info",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "Nenhuma parcela em aberto para o período selecionado!"
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }